import React from "react";

export function Seperator() {
  return <div style={separator}></div>;
}

const separator: React.CSSProperties = {
  flexShrink: 0,
  width: 1,
  height: "auto",
  display: "block",
  backgroundColor: "#ebebeb",
  overflow: "hidden",
  alignSelf: "stretch",
  position: "relative",
  borderRadius: "0px 0px 0px 0px"
};
