import React from "react";
import { Query } from "./Query";
import { QueryResponse } from "./QueryResponse";

export function Event({ queryString, wetteri_response, gpt_response }) {
  return (
    <div style={eventStyle}>
      <Query queryString={queryString} />
      <QueryResponse
        wetteri_query_response={wetteri_response}
        gpt_query_response={gpt_response}
      />
    </div>
  );
}

const eventStyle: React.CSSProperties = {
  boxSizing: "border-box",
  flexShrink: 0,
  //width: 1, // Why does framer want this set to 1?
  height: "min-content" /* 360px */,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "24px 24px 24px 24px",
  backgroundColor: "#ffffff",
  flex: "1 0 0px",
  position: "relative",
  alignContent: "flex-start",
  flexWrap: "nowrap",
  gap: 0,
  borderRadius: "0px 0px 0px 0px",
  borderColor: "#ebebeb",
  borderStyle: "solid",
  borderTopWidth: 1,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0
};
