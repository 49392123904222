import React from "react";
import { useState, useRef } from "react";
import { Event } from "./Event";
import TextField from "@mui/material/TextField";

export function EventList({viewBottom}) {
  const [queries, setQuery] = useState([]);

  const [currentQuery, setCurrentQuery] = useState("");
  const makeQuery = () => {
    console.log("Making query");

    setQuery([
      ...queries,
      {
        query: currentQuery,
        w_response: "",
        g_response: ""
      }
    ]);
    setCurrentQuery("");
    setTimeout(() => viewBottom.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}), 100)
    fetch(
      "https://a-cx-cogsearch.azurewebsites.net/api/search?code=YpAM8r7UqIcNhKIDz360nIwM18TLf-oVJfkIcUUt6dtWAzFuqx3sdA==",
      {
        method: "post",
        body: JSON.stringify({ search_text: currentQuery })
      }
    )
      .then((response) => {
        console.log("Got response");
        return response.text();
      })
      .then((json) => {
        console.log("Got JSON");
        var responseJson = JSON.parse(json);
        setQuery([
          ...queries,
          {
            query: currentQuery,
            w_response: responseJson.cog_result,
            g_response: responseJson.non_cog_result
          }
        ]);
        setTimeout(() => viewBottom.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}), 100)
      })
      .catch((error) => {
        console.log("A comms error has occurred");
        console.error(error);
        setQuery([
          ...queries,
          {
            query: currentQuery,
            w_response: "A communications error occurred, please refresh the page and retry",
            g_response: "A communications error occurred, please refresh the page and retry"
          }
        ]);
        setTimeout(() => viewBottom.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"}), 100)
      });
  };

  return (
    <div>
      {queries.map((item, index) => (
        <div className="input_container" key={index}>
          <Event
            queryString={item.query}
            wetteri_response={item.w_response}
            gpt_response={item.g_response}
          />
        </div>
      ))}
      <div style={titles}>
        <div style={wetteri_title}>WETTERI GPT</div>
        <div style={separator}></div>
        <div style={chatgpt_title}>CHAT GPT</div>
      </div>
      <div style={search}>
        <TextField
          id="filled-search"
          label="Type in a question"
          type="search"
          variant="filled"
          value={currentQuery}
          onChange={(newVal) => {
            setCurrentQuery(newVal.target.value);
          }}
          onKeyDown={(key) => {
            if (key.keyCode === 13) {
              makeQuery();
            }
          }}
          InputProps={{ disableUnderline: true }}
          style={{ width: "min(100vw, 640px)" }}
        />
      </div>
    </div>
  );
}

const search: React.CSSProperties = {
  boxSizing: "border-box",
  flexShrink: 0,
  width: 1152,
  height: 96,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "24px 0px 24px 0px",
  backgroundColor: "#ffffff",
  maxWidth: "100%",
  overflow: "visible",
  zIndex: 1,
  position: "relative",
  alignContent: "center",
  flexWrap: "nowrap",
  gap: 24,
  borderRadius: "0px 0px 0px 0px",
  borderColor: "#ebebeb",
  borderStyle: "solid",
  borderTopWidth: 1,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0
};

const titles: React.CSSProperties = {
  boxSizing: "border-box",
  flexShrink: 0,
  width: "100%",
  height: "min-content" /* 72px */,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "24px 24px 24px 24px",
  position: "relative",
  alignContent: "flex-start",
  flexWrap: "nowrap",
  gap: 24,
  borderRadius: "0px 0px 0px 0px"
};

const wetteri_title: React.CSSProperties = {
  flexShrink: 0,
  width: "48%",
  height: "auto" /* 24px */,
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  wordBreak: "break-word",
  position: "relative",
  fontWeight: 600,
  fontStyle: "normal",
  fontFamily: `"Fira Sans", "Fira Sans Placeholder", sans-serif`,
  color: "rgba(0, 0, 0, 0.4)",
  fontSize: "1rem",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
  lineHeight: 1.5,
  textAlign: "left"
};

const separator: React.CSSProperties = {
  flexShrink: 0,
  width: 1,
  height: "auto",
  display: "block",
  backgroundColor: "#ffffff",
  overflow: "hidden",
  alignSelf: "stretch",
  position: "relative",
  borderRadius: "0px 0px 0px 0px"
};

const chatgpt_title: React.CSSProperties = {
  flexShrink: 0,
  width: "48%",
  height: "auto" /* 24px */,
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  wordBreak: "break-word",
  position: "relative",
  fontWeight: 600,
  fontStyle: "normal",
  fontFamily: `"Fira Sans", "Fira Sans Placeholder", sans-serif`,
  color: "rgba(0, 0, 0, 0.4)",
  fontSize: "1rem",
  letterSpacing: "0.1em",
  textTransform: "uppercase",
  lineHeight: 1.5,
  textAlign: "left"
};
