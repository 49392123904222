import React from "react";

export function Wetteri({ queryResponse }) {
  if (queryResponse) return <div style={wetteri}>{queryResponse}</div>;
  return (
    <div style={wetteri}>
      Fetching answer...
    </div>
  );
}

const wetteri: React.CSSProperties = {
  flexShrink: 0,
  flex: 1,
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
  width: "48%",
  height: "auto" /* 264px */,
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  wordBreak: "break-word",
  overflow: "visible",
  position: "relative",
  fontWeight: 300,
  fontStyle: "normal",
  fontFamily: `"Fira Sans", "Fira Sans Placeholder", sans-serif`,
  color: "#111111",
  fontSize: "1rem",
  letterSpacing: "0.01em",
  lineHeight: 1.5,
  textAlign: "left"
};
