import React from "react";

export default function App() {
  return (
    <div style={main}>
        <img alt="A-CX logo" src="https://www.a-cx.com/wp-content/uploads/2022/08/A-CX.svg"/>
        <h1>Welcome to A-CX Demo Apps</h1>
        <p>Different type of showcases and demonstrations to show our capabilities</p>
    </div>
  );
}

const main: React.CSSProperties = {
    textAlign: "center",
    verticalAlign: "middle",
    display: "block",
    overflow: "visible",
    position: "relative",
    aspectRatio: "1.1363636363636365 / 1",
  };