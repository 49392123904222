import React from "react";

export function Query({ queryString }) {
  const text = queryString;
  return <div style={query}>{text}</div>;
}

const query: React.CSSProperties = {
  flexShrink: 0,
  width: "auto" /* 236px */,
  height: "auto" /* 24px */,
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  wordBreak: "break-word",
  maxWidth: "min(100vw, 640px)",
  position: "relative",
  fontWeight: 400,
  fontStyle: "normal",
  fontFamily: `"Fira Sans", "Fira Sans Placeholder", sans-serif`,
  color: "#545454",
  fontSize: "1rem",
  letterSpacing: 0,
  lineHeight: 1.5,
  textAlign: "left"
};
