import React from "react";
import { Vanilla } from "./Vanilla";
import { Seperator } from "./Seperator";
import { Wetteri } from "./Wetteri";

export function QueryResponse({ wetteri_query_response, gpt_query_response }) {
  return (
    <div style={columns}>
      <Wetteri queryResponse={wetteri_query_response} />
      <Seperator />
      <Vanilla queryResponse={gpt_query_response} />
    </div>
  );
}

const columns: React.CSSProperties = {
  flexShrink: 0,
  width: "100%",
  height: "min-content" /* 264px */,
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  overflow: "hidden",
  position: "relative",
  padding: "0px 0px 0px 0px",
  alignContent: "flex-start",
  flexWrap: "nowrap",
  gap: 24,
  borderRadius: "0px 0px 0px 0px"
};
