import React from "react";
import { EventList } from "./Components/EventsList";

export default function App() {
  const Logo = require('./Resources/AcxLogo.png')
  const divBottom = React.useRef<HTMLInputElement>(null);
  return (
    <div className="App" style={desktop}>
      <div style={navbar}>
        <div style={title}>
          <div style={logo}>
            <img style={logo} src={Logo} alt="A-CX Logo"/>
          </div>
        </div>
      </div>
      <div style={intro}>
        <div style={wetteriGPTDemo}>Wetteri GPT Demo</div>
        <div style={textStyle}>
          This demo has been built to showcase our effectiveness at building
          custom LLMs to serve the specific needs of our clients, and
          demonstrate the superiority of a custom GPT over generic LLMs.
        </div>
      </div>
      <div style={events}>
        <EventList viewBottom={divBottom}/>
      </div>
      <div style={footer} ref={divBottom}>
        <div style={footnote}>© A-CX LLC. 2024</div>
      </div>
    </div>
  );
}

const logo: React.CSSProperties = {
  flexShrink: 0,
  width: 73,
  height: 64,
  display: "block",
  overflow: "visible",
  position: "relative",
  aspectRatio: "1.1363636363636365 / 1",
  borderRadius: "0px 0px 0px 0px"
};

const title: React.CSSProperties = {
  flexShrink: 0,
  flex: "1 0 0px",
  width: 1,
  height: "min-content" /* 64px */,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  overflow: "visible",
  position: "relative",
  padding: "0px 0px 0px 0px",
  alignContent: "flex-start",
  flexWrap: "nowrap",
  borderRadius: "0px 0px 0px 0px"
};

const wetteriGPTDemo: React.CSSProperties = {
  flexShrink: 0,
  width: "100%",
  height: "auto" /* 60px */,
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  wordBreak: "break-word",
  maxWidth: 600,
  position: "relative",
  fontWeight: 700,
  fontStyle: "normal",
  fontFamily: `"Fira Sans", "Fira Sans Placeholder", sans-serif`,
  color: "var(--token-b9c3c957-2c66-49c4-9732-fa196fddac20, #111111)",
  fontSize: 50,
  letterSpacing: 0,
  lineHeight: 1.2,
  textAlign: "left"
};

const textStyle: React.CSSProperties = {
  flexShrink: 0,
  width: "100%",
  height: "auto" /* 144px */,
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  wordBreak: "break-word",
  maxWidth: 660,
  position: "relative",
  fontWeight: 300,
  fontStyle: "normal",
  fontFamily: `"Fira Sans", "Fira Sans Placeholder", sans-serif`,
  color: "rgb(17,17,17)",
  fontSize: "1.3rem",
  letterSpacing: 0,
  lineHeight: 1.5,
  textAlign: "left"
};

const intro: React.CSSProperties = {
  boxSizing: "border-box",
  flexShrink: 0,
  width: 1000,
  height: "min-content" /* 364px */,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "flex-start",
  padding: "64px 24px 64px 24px",
  backgroundColor: "#ffffff",
  maxWidth: "100%",
  overflow: "visible",
  position: "relative",
  alignContent: "flex-start",
  flexWrap: "nowrap",
  gap: 32,
  borderRadius: "0px 0px 0px 0px"
};

const desktop: React.CSSProperties = {
  verticalAlign: "middle",
  width: "100%",
  height: "100vh",//"100%",//"min-content" /* 1615px */,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#ffffff",
  overflow: "auto",
  padding: "0px 0px 0px 0px",
  alignContent: "center",
  flexWrap: "nowrap",
  gap: 0,
  position: "absolute",
  borderRadius: "0px 0px 0px 0px"
};

const events: React.CSSProperties = {
  flexShrink: 0,
  width: "min(100vw, 1000px)",
  height: "min-content" /* 1008px */,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "left",
  overflow: "hidden",
  zIndex: 1,
  position: "relative",
  padding: "0px 0px 0px 0px",
  alignContent: "left",
  flexWrap: "nowrap",
  gap: 0,
  borderRadius: "0px 0px 0px 0px"
};

const navbar: React.CSSProperties = {
  boxSizing: "border-box",
  flexShrink: 0,
  width: "100%",
  height: 80,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "32px 32px 32px 32px",
  backgroundColor: "#ffffff",
  maxWidth: "100%",
  position: "relative",
  alignContent: "center",
  flexWrap: "nowrap",
  gap: 0,
  borderRadius: "0px 0px 0px 0px"
};

const footer: React.CSSProperties = {
  boxSizing: "border-box",
  flexShrink: 0,
  width: "100%",
  height: "min-content" /* 67px */,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px 24px 24px 24px",
  backgroundColor: "#111111",
  maxWidth: "100%",
  overflow: "visible",
  zIndex: 1,
  position: "relative",
  alignContent: "center",
  flexWrap: "nowrap",
  borderRadius: "0px 0px 0px 0px"
};

const footnote: React.CSSProperties = {
  flexShrink: 0,
  width: "auto" /* 119px */,
  height: "auto" /* 19px */,
  whiteSpace: "pre",
  position: "relative",
  fontWeight: 400,
  fontStyle: "normal",
  fontFamily: `"Fira Sans", "Fira Sans Placeholder", sans-serif`,
  color: "#b0b0b0",
  fontSize: "1rem",
  letterSpacing: 0,
  lineHeight: 1.2
};
